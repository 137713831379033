import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { usePromptTemplates } from "../../hooks/usePromptTemplates";
import { TemplateDisplay } from "./TemplateDisplay";
import { useCallback, useEffect, useState } from "react";
import IPromptTemplate from "../../types/interfaces/IPromptTemplate";
import toTitleCase from "../../functions/formatting/toTitleCase";
import { useFormFactor } from "../../hooks/useFormFactor";
import { FormFactor } from "../../types/enums/FormFactor";
import { TemplatePreviewModal } from "./TemplatePreviewModal";

export type TemplateSelectionModalProps = {
  open: boolean;
  onClose: () => void;
  onTemplateFilled: (newText: string) => void;
};

export const TemplateSelectionModal = ({
  open,
  onClose,
  onTemplateFilled,
}: TemplateSelectionModalProps) => {
  const { promptTemplates, templateRoles } = usePromptTemplates();
  const [templatePreviewOpen, setTemplatePreviewOpen] = useState(false);
  const formFactor = useFormFactor();
  const [selectedRole, setSelectedRole] = useState<string | undefined>(
    undefined,
  );
  const [visibleTemplates, setVisibleTemplates] = useState<IPromptTemplate[]>(
    [],
  );
  const [selectedTemplate, setSelectedTemplate] = useState<
    IPromptTemplate | undefined
  >(undefined);
  const [savedText, setSavedText] = useState<string>("");

  const handleSelectRole = useCallback(
    (event: SelectChangeEvent) => {
      setSelectedRole(event.target.value);
      setSelectedTemplate(undefined);
    },
    [setSelectedRole, setSelectedTemplate],
  );

  const handleSelectTemplate = useCallback(
    (event: SelectChangeEvent) => {
      setSelectedTemplate(
        promptTemplates.find((t) => t.name === event.target.value),
      );
    },
    [promptTemplates, setSelectedTemplate],
  );

  const handleTextUpdated = useCallback(
    (newText: string) => {
      setSavedText(newText);
    },
    [selectedTemplate, setSavedText],
  );

  useEffect(() => {
    setVisibleTemplates(
      promptTemplates.filter(
        (t) => t.role.includes(selectedRole ?? "") || t.role.includes("*"),
      ),
    );
  }, [selectedRole, promptTemplates]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      fullScreen={formFactor < FormFactor.Tablet}
    >
      <DialogTitle>Prompt Templates</DialogTitle>
      <DialogContent>
        <TemplatePreviewModal
          newText={savedText}
          open={templatePreviewOpen}
          onTemplateSaved={() => {
            onTemplateFilled(savedText);
          }}
          onPreviewClosed={() => {
            setTemplatePreviewOpen(false);
          }}
        />
        <Stack spacing={2} mt="0.5em">
          <FormControl fullWidth>
            <InputLabel id="template-role-select-label">Role</InputLabel>
            <Select
              labelId="template-role-select-label"
              id="template-role-select"
              value={selectedRole}
              label="Role"
              onChange={handleSelectRole}
            >
              {templateRoles.map((r) => (
                <MenuItem value={r} key={r}>
                  {toTitleCase(r)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="template-select-label">Template</InputLabel>
            <Select
              labelId="template-select-label"
              id="template-select"
              value={selectedTemplate?.name}
              label="Template"
              onChange={handleSelectTemplate}
            >
              {visibleTemplates.map((t) => (
                <MenuItem value={t.name} key={t.name}>
                  {toTitleCase(t.name)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {selectedTemplate !== undefined ? (
            <>
              <hr />
              <Typography variant={"h6"}>Template Fields</Typography>
              <Box m="0.5em">
                <TemplateDisplay
                  template={selectedTemplate}
                  onTextUpdated={handleTextUpdated}
                />
              </Box>
            </>
          ) : (
            <></>
          )}
          <Stack direction="row">
            <Button onClick={onClose} color="error">
              Cancel
            </Button>
            <Button
              onClick={() => {
                setTemplatePreviewOpen(true);
              }}
            >
              Preview
            </Button>

            <Button
              onClick={() => {
                onTemplateFilled(savedText);
                onClose();
              }}
            >
              Insert
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
