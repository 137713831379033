import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import IConversationSettings from "../types/interfaces/IConversationSettings";
import IModel from "../types/interfaces/IModel";
import getDefaultModelFromList from "../functions/comparisons-arrays/getDefaultModelFromList";
import sortLargeLanguageModels from "../functions/comparisons-arrays/sortLargeLanguageModels";
import { useOidcConfigurationName } from "../hooks/useOidcConfigurationName";
import { useOidcAccessToken, useOidcUser } from "@axa-fr/react-oidc";
import { useUserInfo } from "../hooks/useUserInfo";
import { useChatOptions } from "../hooks/useChatOptions";
import { useAvailableModels } from "../hooks/useAvailableModels";
import { useFormFactor } from "../hooks/useFormFactor";
import { FormFactor } from "../types/enums/FormFactor";
import { useRagSources } from "../hooks/useRagSources";
import RagSourceMultiSelect from "./Controls/RagSourceMultiSelect";

export type ConversationSettingsProps = {
  open: boolean;
  onClose: () => void;
  onSave: (newSettings: IConversationSettings) => void;
  currentModel: IModel;
  currentSystemPrompt: string;
  currentPii: boolean;
  currentRag: string[];
  defaultSystemPrompt: string;
};

export default function ConversationSettings({
  open,
  onClose,
  onSave,
  currentModel,
  currentSystemPrompt,
  currentPii,
  currentRag,
  defaultSystemPrompt,
}: ConversationSettingsProps) {
  const { availableModels } = useAvailableModels();
  const { oidcConfigurationName } = useOidcConfigurationName();
  const { oidcUser } = useOidcUser(oidcConfigurationName);
  const oidcAccessToken = useOidcAccessToken(oidcConfigurationName);
  const { roles } = useUserInfo(oidcUser, oidcAccessToken);
  const { chatOptions } = useChatOptions();
  const formFactor = useFormFactor();
  const ragSources = useRagSources();

  const [systemPrompt, setSystemPrompt] = useState<string>(currentSystemPrompt);
  const [model, setModel] = useState<IModel>(currentModel);
  const [rag, setRag] = useState<string[]>(currentRag);
  const [pii, setPii] = useState<boolean>(currentPii);

  const showRag = chatOptions.show_rag;

  const handleClose = () => {
    setPii(currentPii);
    setRag(currentRag);
    setModel(currentModel);
    setSystemPrompt(currentSystemPrompt);
    onClose();
  };

  useEffect(() => {
    setModel(currentModel);
    setRag(currentRag);
    setPii(currentPii);
    setSystemPrompt(currentSystemPrompt);
  }, [currentSystemPrompt, currentPii, currentRag, currentModel]);

  const handleSave = () => {
    onSave({
      systemPrompt: systemPrompt,
      model: model,
      pii: pii,
      rag: rag,
    });
    onClose();
  };

  const handleSystemPromptChange = (event: ChangeEvent<{ value: unknown }>) => {
    setSystemPrompt(event.target.value as string);
  };

  const handleModelChange = (event: SelectChangeEvent<{ value: unknown }>) => {
    setModel(
      availableModels.find((llm) => llm.internal_name === event.target.value) ??
        getDefaultModelFromList(availableModels),
    );
  };

  /*
  const handlePiiChange = (event: ChangeEvent<{ value: unknown }>) => {
    setPii((prev) => !prev);
  };
   */

  const handleRagChange = (newRag: string[]) => {
    setRag(newRag);
  };

  const handleResetSystemPrompt = () => {
    setSystemPrompt(defaultSystemPrompt);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth={true}
      fullScreen={formFactor < FormFactor.Tablet}
    >
      <DialogTitle>Conversation Settings</DialogTitle>
      <DialogContent>
        <Box>
          <FormGroup
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1em",
              mt: "0.5em",
            }}
          >
            <TextField
              multiline={true}
              maxRows={10}
              value={systemPrompt}
              label={"System Prompt"}
              onChange={handleSystemPromptChange}
            />
            <Button onClick={handleResetSystemPrompt}>
              Reset System Prompt to Global Default
            </Button>
            <FormControl>
              <InputLabel id="modelSelectLabel">Model</InputLabel>
              <Select
                labelId="modelSelectLabel"
                // @ts-ignore
                value={model.internal_name}
                label="Model"
                onChange={handleModelChange}
              >
                {
                  /*Object.values(IModel).map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))*/
                  availableModels
                    .filter((m) => {
                      let x = Array.isArray(m.allowed_groups)
                        ? m.allowed_groups
                        : [m.allowed_groups];
                      return x.some((g) => roles?.includes(g) || g === "*");
                    })
                    .sort(sortLargeLanguageModels)
                    .map((m) => (
                      <MenuItem key={m.internal_name} value={m.internal_name}>
                        {m.default ? (
                          <b>{m.display_name + " (Default)"}</b>
                        ) : (
                          m.display_name
                        )}
                      </MenuItem>
                    ))
                }
              </Select>
            </FormControl>
            {showRag ? (
              <RagSourceMultiSelect
                onRagChange={handleRagChange}
                currentRagValue={rag}
              />
            ) : (
              <></>
            )}
          </FormGroup>
          <Box display="flex" sx={{ mt: "1em" }}>
            <Button onClick={handleClose} color="error">
              Cancel
            </Button>
            <Button onClick={handleSave}>Save</Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
