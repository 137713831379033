import IConversationInfo from "./types/interfaces/IConversationInfo";
import { Box, CssBaseline, Drawer, Toolbar } from "@mui/material";
import React, { Fragment, useCallback, useEffect, useState } from "react";
import { v4 as uuid4 } from "uuid";
import ConversationList from "./components/ConversationList";
import ActiveConversationWindow from "./components/ActiveConversationWindow";
import IChatMessage from "./types/interfaces/IChatMessage";
import downloadText from "./functions/chat-tools/downloadText";
import getDefaults from "./functions/defaults";
import HelpDialog from "./components/HelpDialog";
import { db } from "./database/db";
import { useLiveQuery } from "dexie-react-hooks";
import { MessageRole } from "./types/enums/MessageRole";
import TopBar from "./components/TopBar";
import useLocalStorage from "./hooks/useLocalStorage";
import GlobalSettings from "./components/GlobalSettings";
import { useChatOptions } from "./hooks/useChatOptions";
import { useFormFactor } from "./hooks/useFormFactor";
import ConversationListItem from "./components/ConversationListItem";
import { useSidebarOpen } from "./hooks/useSidebarOpen";
import { FormFactor } from "./types/enums/FormFactor";
import { PromotionSidebar } from "./components/promotion/PromotionSidebar";

export default function Chat() {
  const chatOptionsContext = useChatOptions();
  const chatOptions = chatOptionsContext.chatOptions;
  const formFactor = useFormFactor();
  const { sidebarOpen, sidebarWidth, closeSidebar } = useSidebarOpen();
  const [activeConversationObject, setActiveConversationObject] =
    useState<IConversationInfo | null>(null);
  const [helpOpen, setHelpOpen] = useState<boolean>(false);
  const [globalSettingsOpen, setGlobalSettingsOpen] = useState<boolean>(false);
  const [defaultSystemPrompt, setDefaultSystemPrompt] = useLocalStorage<string>(
    "defaultSystemPrompt",
    chatOptions.default_system_prompt,
  );
  const [useRagByDefault, setUseRagByDefault] = useLocalStorage<boolean>(
    "useRagByDefault",
    getDefaults().useRagByDefault,
  );

  // @ts-ignore
  const dbConversations = useLiveQuery<IConversationInfo[]>(() =>
    db.conversationInfo.orderBy("updated").reverse().toArray(),
  );

  const selectConversation = useCallback(
    async (id: string | null) => {
      if (id === null || id === undefined) {
        setActiveConversationObject(null);
        return;
      }
      try {
        const currentConversation = await db.conversationInfo.get(id);
        if (currentConversation !== undefined) {
          setActiveConversationObject(currentConversation);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [closeSidebar, formFactor],
  );

  const addConversationDb = useCallback(async () => {
    let newId = uuid4().toString();
    try {
      await db.conversationInfo.add({
        id: newId,
        name: getDefaults().defaultConversationName,
        updated: new Date(),
        named: false,
      });
      const newSystemMessageId = uuid4().toString();
      await db.chatMessage.add({
        conversationId: newId,
        id: newSystemMessageId,
        content: getDefaults().defaultSystemPrompt,
        role: MessageRole.System,
        updated: new Date(),
        model: null,
        hasFeedback: false,
      });
      await selectConversation(newId);
    } catch (error) {
      console.log(error);
    }
  }, [selectConversation]);

  useEffect(() => {
    (async function () {
      const conversations = await db.conversationInfo.toArray();
      for (let conversation of conversations) {
        const messageCount = await db.chatMessage
          .where("conversationId")
          .equals(conversation.id)
          .count();

        if (messageCount < 2 && conversation.name === "New Conversation") {
          await db.conversationInfo
            .where("id")
            .equals(conversation.id)
            .delete();
        }
      }
    })();
    addConversationDb();
  }, []);

  async function editConversationDb(
    id: string,
    newName: string,
    updated: Date,
    named: boolean,
  ) {
    try {
      await db.conversationInfo.put(
        { id: id, name: newName, updated: updated, named: named },
        [id],
      );
    } catch (error) {
      console.log(error);
    }
  }

  async function deleteConversationDb(id: string) {
    try {
      if (activeConversationObject?.id === id) {
        await selectConversation(null);
      }
      await db.chatMessage
        .where("conversationId")
        .equals(id ?? "")
        .delete();
      await db.conversationInfo.delete(id);
    } catch (error) {
      console.log(error);
    }
  }

  const handleTranscriptDownloaded = (history: IChatMessage[]) => {
    const content = JSON.stringify(history, null, 2);
    downloadText(activeConversationObject?.name + ".json", content);
  };

  const handleOpenHelp = () => {
    setHelpOpen(true);
  };

  const handleCloseHelp = () => {
    setHelpOpen(false);
  };

  const handleGlobalSettingsOpen = () => {
    setGlobalSettingsOpen(true);
  };

  const handleGlobalSettingsClose = () => {
    setGlobalSettingsOpen(false);
  };

  const handleGlobalSettingsSave = (
    newDefaultSystemPrompt: string,
    newUseRagByDefault: boolean,
  ) => {
    setDefaultSystemPrompt(newDefaultSystemPrompt);
    setUseRagByDefault(newUseRagByDefault);
  };

  return (
    <>
      <HelpDialog open={helpOpen} onClose={handleCloseHelp} />
      <GlobalSettings
        open={globalSettingsOpen}
        currentDefaultSystemPrompt={defaultSystemPrompt}
        currentUseRagByDefault={useRagByDefault}
        onSave={handleGlobalSettingsSave}
        onClose={handleGlobalSettingsClose}
      />
      <Box>
        <Box>
          <CssBaseline />
          <TopBar
            onHelpOpened={handleOpenHelp}
            onGlobalSettingsOpened={handleGlobalSettingsOpen}
          />
          <Drawer
            sx={{
              width: formFactor === FormFactor.Mobile ? "100vw" : sidebarWidth,
              "& .MuiDrawer-paper": {
                width:
                  formFactor === FormFactor.Mobile ? "100vw" : sidebarWidth,
                boxSizing: "border-box",
                overflowX: "hidden",
              },
            }}
            open={sidebarOpen}
            variant="persistent"
            anchor="left"
          >
            <Toolbar />
            <ConversationList
              onConversationAdded={addConversationDb}
              onGlobalSettingsOpened={handleGlobalSettingsOpen}
            >
              {dbConversations?.map((c) => (
                <ConversationListItem
                  conversation={c}
                  onConversationInfoChanged={editConversationDb}
                  onConversationDeleted={deleteConversationDb}
                  onConversationClicked={selectConversation}
                  active={c.id === activeConversationObject?.id}
                  key={c.id}
                />
              ))}
            </ConversationList>
          </Drawer>
          <Box
            component="main"
            sx={{
              ml:
                formFactor === FormFactor.Mobile
                  ? 0
                  : sidebarOpen
                    ? `${sidebarWidth}px`
                    : 0,
              mt: 0,
              mb: 0,
              mr:
                formFactor > FormFactor.Tablet && chatOptions.use_promotions
                  ? `${sidebarWidth}px`
                  : 0,
              width:
                formFactor === FormFactor.Mobile
                  ? "100%"
                  : sidebarOpen
                    ? `calc(100% - ${formFactor > FormFactor.Tablet && chatOptions.use_promotions ? sidebarWidth * 2 : sidebarWidth}px)`
                    : `calc(100% - ${formFactor > FormFactor.Tablet && chatOptions.use_promotions ? sidebarWidth : 0}px)`,
            }}
          >
            <ActiveConversationWindow
              conversationId={activeConversationObject?.id}
              onTranscriptDownloaded={handleTranscriptDownloaded}
              defaultSystemPrompt={defaultSystemPrompt}
              useRagByDefault={useRagByDefault}
            />
          </Box>
          {formFactor > FormFactor.Tablet && chatOptions.use_promotions ? (
            <Drawer variant="permanent" anchor="right">
              <Toolbar />
              <PromotionSidebar />
            </Drawer>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
}
