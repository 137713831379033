import { Box, Button, CssBaseline, Dialog, Typography } from "@mui/material";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useDarkMode } from "./hooks/useDarkMode";
import { useOidcConfigList } from "./hooks/useOidcConfigList";
import { useOidcConfigurationName } from "./hooks/useOidcConfigurationName";

export default function LoginPage() {
  const { darkModeEnabled } = useDarkMode();
  const { oidcConfigList } = useOidcConfigList();
  const { setOidcConfigurationName } = useOidcConfigurationName();

  const navigate = useNavigate();

  return (
    <Fragment>
      <CssBaseline />
      <Dialog open={true} maxWidth="md" fullWidth={true}>
        <Box
          sx={{ minHeight: "50vh" }}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            sx={{ gap: "1em" }}
          >
            <img
              alt="logo"
              src={
              process.env.REACT_APP_WHITELABEL_LOGINLOGO ?? (
                darkModeEnabled
                  ? process.env.REACT_APP_WHITELABEL_DARKLOGO ??
                    "/logo-dark.png"
                  : process.env.REACT_APP_WHITELABEL_LIGHTLOGO ??
                    "/logo-light.png"
                  )
              }
              style={{ maxWidth: "60%" }}
            />
            <Typography variant="h5">Authenticate to Continue</Typography>
            {oidcConfigList.map((c) => (
              <Button
                variant="contained"
                onClick={() => {
                  setOidcConfigurationName(c.name);
                  navigate("/");
                }}
              >
                Continue with {c.name.toString()}
              </Button>
            ))}
          </Box>
        </Box>
      </Dialog>
    </Fragment>
  );
}
