import {
  IconButton,
  Menu,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import {
  AutoFixHigh,
  Cancel,
  Download,
  MenuSharp,
  Send,
  Settings,
  Upload,
} from "@mui/icons-material";
import IChatMessage from "../../types/interfaces/IChatMessage";
import React, { ChangeEvent, Fragment, useState } from "react";
import { MessageRole } from "../../types/enums/MessageRole";
import { KeyboardEvent, DragEvent } from "react";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import FileAttachment from "./FileAttachment";
import identifyAndParseUploadedFile from "../../functions/chat-tools/identifyAndParseUploadedFile";
import { v4 } from "uuid";
import { useSending } from "../../hooks/useSending";
import { TemplateSelectionModal } from "../templates/TemplateSelectionModal";
import { useFormFactor } from "../../hooks/useFormFactor";
import { FormFactor } from "../../types/enums/FormFactor";
import { usePromptTemplates } from "../../hooks/usePromptTemplates";

export type ComposeAreaProps = {
  onSend: (message: IChatMessage) => void;
  enabled: boolean;
  onConversationSettingsOpen: () => void;
  onExportTranscript: () => void;
  onError: (notificationText: string) => void;
  conversationId: string;
  cancelStream: () => void;
};

export default function ComposeArea({
  onSend,
  enabled,
  onConversationSettingsOpen,
  onExportTranscript,
  onError,
  conversationId,
  cancelStream,
}: ComposeAreaProps) {
  const { isSending } = useSending();
  const formFactor = useFormFactor();
  const { useTemplates } = usePromptTemplates();
  const [text, setText] = useState("");
  const [attachedFiles, setAttachedFiles] = useState<
    { id: string; name: string; content: string }[]
  >([]);
  const [isOver, setIsOver] = useState(false);
  const [templateModalOpen, setTemplateModalOpen] = useState(false);

  const handleMessageSubmit = () => {
    if (text.trim() === "") {
      onError("You cannot send a blank message.");
      return;
    }
    const newId = v4();
    const attachedFilesContent = attachedFiles
      .map(
        (file) =>
          `\n===== ATTACHED FILE BELOW======\n${file.name}\n${file.content}`,
      )
      .join("\n\n");

    onSend({
      id: newId,
      role: MessageRole.User,
      content: text + attachedFilesContent,
      conversationId: conversationId,
      updated: new Date(),
      model: null,
      hasFeedback: false,
    });

    setText("");
    setAttachedFiles([]);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey && enabled) {
      e.preventDefault();
      handleMessageSubmit();
    }
  };

  // @ts-ignore
  const closePopup = (ps: PopupState) => {
    ps.close();
  };

  const handleTemplateFilled = (newText: string) => {
    setText(newText);
    setTemplateModalOpen(false);
  };

  const handleFileUpload = (files: FileList | null) => {
    if (files !== null) {
      Array.from(files).forEach(async (file) => {
        const fileText = await identifyAndParseUploadedFile(file);
        if (fileText === undefined) {
          onError(
            "Sorry, there is an issue with this file or the file type isn't currently supported.",
          );
        } else {
          setAttachedFiles((currentFiles) => [
            ...currentFiles,
            { id: v4(), name: file.name, content: fileText },
          ]);
        }
      });
    }
  };

  const handleDeleteFile = (fileId: string) => {
    setAttachedFiles((currentFiles) =>
      currentFiles.filter((file) => file.id !== fileId),
    );
  };

  const handleDragOver = (e: DragEvent) => {
    e.preventDefault();
    setIsOver(true);
  };

  const handleDragLeave = () => {
    setIsOver(false);
  };

  const handleDrop = (e: DragEvent) => {
    e.preventDefault();
    setIsOver(false);
    handleFileUpload(e.dataTransfer.files);
  };

  return (
    <Stack
      direction="column"
      useFlexGap={true}
      gap="0.5em"
      alignItems="center"
      justifyContent="center"
      sx={{
        position: "sticky",
        padding: "0.5em",
        width: "100%",
        marginTop: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        maxHeight: "100%",
        bottom: 0,
      }}
    >
      <TemplateSelectionModal
        open={templateModalOpen}
        onClose={() => setTemplateModalOpen(false)}
        onTemplateFilled={handleTemplateFilled}
      />
      <Stack
        direction="row"
        sx={{
          width: "100%",
          maxWidth: "960px",
          overflowX: "auto",
          whiteSpace: "nowrap",
          marginLeft: "auto",
          marginRight: "auto",
          scrollbarWidth: "none", // for Firefox
          "&::-webkit-scrollbar": {
            display: "none", // for Chrome, Safari, Edge
          },
        }}
      >
        {attachedFiles.map((file, index) => (
          <FileAttachment
            key={index}
            onDeleteFile={() => handleDeleteFile(file.id)}
            fileName={file.name}
          />
        ))}
      </Stack>
      <Stack
        direction="row"
        useFlexGap={true}
        gap="0.5em"
        alignItems="center"
        justifyContent="center"
        sx={{
          position: "sticky",
          padding: "0.5em",
          width: "100%",
          marginTop: "auto",
          marginLeft: "auto",
          marginRight: "auto",
          maxHeight: "100%",
          bottom: 0,
        }}
      >
        <PopupState
          parentPopupState={null}
          variant="popover"
          popupId="message-settings-popover"
          disableAutoFocus={false}
        >
          {(popupState) => (
            <Fragment>
              <Tooltip title="Conversation actions">
                <IconButton
                  sx={{ height: "100%", alignSelf: "center" }}
                  size="large"
                  {...bindTrigger(popupState)}
                >
                  <MenuSharp />
                </IconButton>
              </Tooltip>
              <Menu {...bindMenu(popupState)}>
                <MenuItem component="label">
                  <Upload />
                  &nbsp; Attach files
                  <input
                    type="file"
                    hidden
                    multiple
                    onChange={(e) => {
                      handleFileUpload(e.target.files);
                      closePopup(popupState);
                    }}
                  />
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onExportTranscript();
                    closePopup(popupState);
                  }}
                >
                  <Download />
                  &nbsp; Download conversation transcript
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    onConversationSettingsOpen();
                    closePopup(popupState);
                  }}
                >
                  <Settings />
                  &nbsp; Change conversation settings...
                </MenuItem>
                {formFactor <= FormFactor.Tablet && useTemplates ? (
                  <MenuItem
                    onClick={() => {
                      setTemplateModalOpen(true);
                      closePopup(popupState);
                    }}
                  >
                    <AutoFixHigh />
                    &nbsp; Use a prompt template...
                  </MenuItem>
                ) : null}{" "}
              </Menu>
            </Fragment>
          )}
        </PopupState>
        <TextField
          multiline={true}
          value={text}
          onKeyDown={handleKeyDown}
          fullWidth={true}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setText(event.target.value);
          }}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
          maxRows={5}
          placeholder="Write a message"
          sx={{
            maxWidth: "960px",
            ...(isOver && {
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderStyle: "dashed",
                  borderWidth: "2.5px",
                },
              },
            }),
          }}
        />
        {formFactor > FormFactor.Tablet && useTemplates ? (
          <Tooltip title={"Use a prompt template"}>
            <IconButton
              content="templates"
              size="large"
              onClick={() => setTemplateModalOpen(true)}
              sx={{ height: "100%", alignSelf: "center" }}
            >
              <AutoFixHigh />
            </IconButton>
          </Tooltip>
        ) : (
          <></>
        )}
        {isSending ? (
          <Tooltip title="Cancel generation">
            <IconButton
              content="cancel"
              size="large"
              onClick={cancelStream}
              sx={{ height: "100%", alignSelf: "center" }}
            >
              <Cancel />
            </IconButton>
          </Tooltip>
        ) : (
          <Tooltip title="Send message">
            <IconButton
              content="send"
              disabled={isSending || !enabled}
              size="large"
              onClick={handleMessageSubmit}
              sx={{ height: "100%", alignSelf: "center" }}
            >
              <Send />
            </IconButton>
          </Tooltip>
        )}
      </Stack>
    </Stack>
  );
}
